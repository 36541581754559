import {
  CO_BEE_FEATURE_APPROVED_STATUS,
  CO_BEE_FEATURE_CANCEL_STATUS,
  CO_BEE_FEATURE_FAILED_STATUS,
  CO_BEE_FEATURE_PENDING_STATUS,
  CO_BEE_FEATURE_REFUNDED_STATUS,
} from '_utils/constants'
import moment from 'moment'
import React from 'react'
import Modal from 'react-bootstrap/Modal'

const tableHeaders = ['#', 'Status', 'Last Modified']

const renderStatus = (status) => {
  switch (status) {
    case CO_BEE_FEATURE_APPROVED_STATUS:
      return <span style={{ color: '#51aaaa' }}>Approved</span>
    case CO_BEE_FEATURE_FAILED_STATUS:
      return <span style={{ color: '#34495e' }}>Failed</span>
    case CO_BEE_FEATURE_PENDING_STATUS:
      return <span style={{ color: '#ff6903' }}>Pending</span>
    case CO_BEE_FEATURE_CANCEL_STATUS:
      return <span style={{ color: '#c0392b' }}>Cancelled</span>
    case CO_BEE_FEATURE_REFUNDED_STATUS:
      return <span style={{ color: '#2c3e50' }}>Refunded</span>
    default:
      return null
  }
}

function ModalDetail({ isOpen, setIsOpenModalDetail, selectedInvoice }) {
  const toggleModal = () => setIsOpenModalDetail(!isOpen)

  return (
    <Modal show={isOpen} onHide={toggleModal} className='modal-refund'>
      <Modal.Header closeButton>
        <Modal.Title>Invoice - {selectedInvoice?.id?.slice(0, 6)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='history-log'>
          <h4 className='user__detail-group-list-heading'>History change status</h4>
          <div className='scroll__mobile'>
            <table className='table table-striped'>
              <thead>
                <tr className='user__detail-group-list-title'>
                  {tableHeaders.map((item, i) => (
                    <th
                      key={i}
                      scope='col'
                      className={item === 'Refund ($)' ? 'text-end' : 'text-start'}
                    >
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className='custom-scrollbar'>
                {selectedInvoice?.tracking?.length ? (
                  [...selectedInvoice.tracking]
                    .sort((a, b) => b.createdAt - a.createdAt)
                    .map((item, index) => (
                      <tr key={index}>
                        <th scope='row' className='user__detail-group-list-mumber-index'>
                          {index + 1}
                        </th>
                        <td className='group__list__creation-date'>{renderStatus(item.status)}</td>
                        <td className='group__list__creation-date'>
                          {moment.unix(item.createdAt).format('DD/MM/YYYY - HH:mm')}
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td
                      colSpan={tableHeaders.length}
                      className='text-center fw-bold'
                      style={{ color: '#7c797a' }}
                    >
                      No Any Changes Status
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type='button'
          className='btn btn-color-primary text-white rounded-pill w-25'
          onClick={toggleModal}
        >
          Ok
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalDetail
