/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import ModalRefund from '_components/ModalRefund'
import {
  cancelOrRefundInvoice,
  clearInvoiceSelected,
  getListInvoice,
} from '_redux/reducers/invoice'
import {
  CO_BEE_FEATURE_CANCEL_STATUS,
  CO_BEE_FEATURE_REFUNDED_STATUS,
  PACKAGE_TYPE_PREMIUM_ACCOUNT,
} from '_utils/constants'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { ToastContainer, toast } from 'react-toastify'
import * as yup from 'yup'

import Pagination from '_components/Pagination'
import { isNaN } from 'lodash'
import InvoiceItem from './InvoiceItem'
import ModalDetail from './ModalDetail'
import './style.css'

const PageSize = 5

const priceRefundSchema = yup.object().shape({
  priceRefund: yup
    .number()
    .test('is-number', 'Price refund must be a number', (value) => {
      if (value === undefined || value === null || value === '') {
        return true
      }
      const parsedValue = typeof value === 'string' ? parseFloat(value.replace(/,/g, '')) : value
      return !isNaN(parsedValue)
    })
    .min(0, 'Price refund cannot be negative')
    .required('Price refund is required')
    .transform((value, originalValue) => {
      if (typeof originalValue === 'string') {
        return parseFloat(originalValue.replace(/,/g, ''))
      }
      return value
    }),
})

function ListInvoicePackageType() {
  const dispatch = useDispatch()
  const priceRefundRef = useRef(null)
  const { pathname } = useLocation()
  const { listInvoice } = useSelector((state) => state.invoice)

  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [invoice, setInvoice] = useState({})
  const [isChangingStatus, setIsChangingStatus] = useState(false)
  const [priceRefund, setPriceRefund] = useState('')
  const [isOpenModalRefund, setIsOpenModalRefund] = useState(false)
  const [errorMessagePrice, setErrorMessagePrice] = useState('')
  const [isOpenModalDetail, setIsModalDetail] = useState(false)

  const tableHeaders = [
    'No',
    'Info',
    'Id',
    'User Name',
    'Recurring',
    'Created Date',
    'Last Modified',
    'Refund ($)',
    'Status',
  ]

  const fetchData = async () => {
    await dispatch(getListInvoice(PACKAGE_TYPE_PREMIUM_ACCOUNT))
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (isOpenModalRefund) {
      priceRefundRef.current.focus()
    }
  }, [isOpenModalRefund])

  useEffect(() => {
    if (!isOpenModalDetail) {
      setInvoice(null)
    }
  }, [isOpenModalDetail])

  useEffect(() => {
    if (pathname === '/invoice-package-type') {
      dispatch(clearInvoiceSelected())
    }
  }, [pathname])

  const toggleModalRefund = () => setIsOpenModalRefund(!isOpenModalRefund)

  const toggleModalDetail = (item) => {
    setInvoice(item)
    setIsModalDetail(!isOpenModalDetail)
  }

  const alignTextTh = (item) => {
    switch (item) {
      case 'Refund ($)':
        return 'text-end'
      case 'Status':
        return 'text-end'
      default:
        return 'text-start'
    }
  }

  const currentData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize

    if (listInvoice?.length <= PageSize) {
      return listInvoice
    }

    return listInvoice?.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, JSON.stringify(listInvoice)])

  const validatePriceRefund = async (value) => {
    try {
      const result = await priceRefundSchema.validate({ priceRefund: value })
      return result
    } catch (error) {
      setErrorMessagePrice(error.message)
    }

    return null // Add a return statement at the end of the function
  }

  const onRefund = async (e) => {
    e.preventDefault()
    try {
      const valid = await validatePriceRefund(priceRefund)
      if (valid) {
        setIsChangingStatus(true)
        await dispatch(
          cancelOrRefundInvoice({
            refund:
              typeof priceRefund === 'string'
                ? parseFloat(priceRefund.replace(/,/g, ''))
                : priceRefund,
            status: CO_BEE_FEATURE_REFUNDED_STATUS,
            invoiceId: invoice?.id,
            packageType: PACKAGE_TYPE_PREMIUM_ACCOUNT,
          }),
        )

        setInvoice({ ...invoice, status: CO_BEE_FEATURE_REFUNDED_STATUS })
        toast.success('Change status success!')
        toggleModalRefund()
        setInvoice(null)
      }
    } catch (error) {
      toast.error(error.message)
      toggleModalRefund()
    } finally {
      setPriceRefund('')
      setIsChangingStatus(false)
    }
  }

  // eslint-disable-next-line no-unused-vars
  const onCancel = async (item) => {
    try {
      setInvoice(item)
      setIsChangingStatus(true)
      await dispatch(
        cancelOrRefundInvoice({
          status: CO_BEE_FEATURE_CANCEL_STATUS,
          invoiceId: item?.id,
          packageType: PACKAGE_TYPE_PREMIUM_ACCOUNT,
        }),
      )

      setInvoice({ ...invoice, status: CO_BEE_FEATURE_CANCEL_STATUS })

      toast.success('Change status success!')
    } catch (error) {
      toast.error(error.message)
    } finally {
      setIsChangingStatus(false)
      setInvoice(null)
    }
  }

  const onChangeStatus = async (value, item) => {
    const status = Number(value)

    if (status === CO_BEE_FEATURE_REFUNDED_STATUS) {
      if (window.confirm('Do you sure change to refund status?')) {
        setInvoice(item)
        toggleModalRefund()
      }
    }

    if (status === CO_BEE_FEATURE_CANCEL_STATUS) {
      if (window.confirm('Do you sure change to cancel status?')) {
        await onCancel(item)
      }
    }
  }

  if (loading) {
    return (
      <div className='invoice-package-type'>
        <div className='d-flex flex-column align-items-center justify-content-center'>
          <div className='spinner-border text-primary' role='status' />
          <span className='fw-bold mt-2'>Loading...</span>
        </div>
      </div>
    )
  }

  return (
    <div className='invoice-package-type p-3 pt-0'>
      <ToastContainer autoClose={2000} />
      <h3 className='group__list-heading'>Invoice Premium Account</h3>
      <div className='scroll__mobile'>
        <table className='table table-hover'>
          <thead>
            <tr className='user__detail-group-list-title'>
              {tableHeaders.map((item, i) => (
                <th key={i} className={alignTextTh(item)}>
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentData.length ? (
              <>
                {currentData?.map((item, i) => (
                  <InvoiceItem
                    item={item}
                    key={i}
                    i={i}
                    onChangeStatus={onChangeStatus}
                    isChangingStatus={isChangingStatus}
                    toggleModalDetail={toggleModalDetail}
                  />
                ))}
              </>
            ) : (
              <tr>
                <td colSpan={tableHeaders.length} className='text-center fw-bold text-secondary'>
                  No data
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
          className='pagination-bar'
          currentPage={currentPage}
          totalCount={listInvoice.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
      <ModalRefund
        isOpen={isOpenModalRefund}
        toggleModal={toggleModalRefund}
        onSubmit={onRefund}
        priceRefund={priceRefund}
        setPriceRefund={setPriceRefund}
        selectedInvoice={invoice}
        isChangingStatus={isChangingStatus}
        errorMessagePrice={errorMessagePrice}
        inputRef={priceRefundRef}
      />
      <ModalDetail
        isOpen={isOpenModalDetail}
        setIsOpenModalDetail={setIsModalDetail}
        selectedInvoice={invoice}
      />
    </div>
  )
}

export default ListInvoicePackageType
