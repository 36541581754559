import axiosClient from './axiosClient'

const invoiceApi = {
  getListInvoice: (packageType) => {
    const url = '/list-invoice-co-bee-feature'
    return axiosClient.get(url, {
      params: {
        packageType,
      },
    })
  },
  cancelOrRefundInvoice: ({ invoiceId, status, refund = null, packageType }) => {
    const url = '/cancel-co-bee-feature'
    return axiosClient.post(url, {
      invoiceId,
      status,
      refund,
      packageType,
    })
  },
}

export default invoiceApi
