/* eslint-disable no-unused-vars */
import { getDashBroad } from '_redux/reducers/dashboard'
import { getListAllUsers } from '_redux/reducers/users'
import { getDataReport } from '_utils/localData'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import TableDataItem from '../components/TableDataItem'

import 'react-toastify/dist/ReactToastify.css'
import './style.css'

function TablesData() {
  const dispatch = useDispatch()

  const { listDashBroad } = useSelector((state) => state.dashBroad)

  const [dataDashBroad, setDataDashBroad] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const dataReport = getDataReport()
    if (dataReport) {
      setDataDashBroad(dataReport)
    } else {
      dispatch(getDashBroad)
      setDataDashBroad(listDashBroad)
    }

    const fetchData = async () => {
      await dispatch(getListAllUsers())
      setLoading(false)
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (listDashBroad) {
      setDataDashBroad(listDashBroad)
    }
  }, [listDashBroad])

  const handleRefreshData = () => {
    dispatch(getDashBroad)
    toast.success('Update successful')
  }

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <>
      <ToastContainer />
      <div className='container-fluid'>
        <div className='container'>
          <Button className='refresh__data' onClick={handleRefreshData} variant='primary'>
            Update Report
          </Button>
          <div className='row'>
            <TableDataItem data={dataDashBroad} />
          </div>
        </div>
      </div>
    </>
  )
}

export default TablesData
