import { formatNumberToCurrencyHasDot } from '_utils/functions'
import React from 'react'
import Modal from 'react-bootstrap/Modal'
import './style.css'

function ModalRefund({
  isOpen,
  onSubmit,
  toggleModal,
  priceRefund,
  setPriceRefund,
  selectedInvoice,
  isChangingStatus,
  errorMessagePrice,
  inputRef,
}) {
  return (
    <Modal show={isOpen} onHide={toggleModal} className='modal-refund'>
      <form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Refund - {selectedInvoice?.id?.slice(0, 6)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>Amount Refund ($):</label>
          <input
            ref={inputRef}
            type='text'
            className='form-control'
            placeholder='Enter amount to refund'
            value={formatNumberToCurrencyHasDot(priceRefund)}
            onChange={(e) => setPriceRefund(e.target.value)}
          />
          {errorMessagePrice && <small className='text-danger'>{errorMessagePrice}</small>}
        </Modal.Body>
        <Modal.Footer>
          <button
            type='submit'
            className='btn btn-color-primary text-white rounded-pill w-25'
            disabled={isChangingStatus}
          >
            {isChangingStatus ? 'Refunding...' : 'Refund'}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default ModalRefund
