import invoiceApi from '_api/invoice'
import { CO_BEE_FEATURE_CANCEL_STATUS } from '_utils/constants'

const initialState = {
  listInvoice: [],
  selectedInvoice: null,
}

const GET_LIST_INVOICE = 'GET_LIST_INVOICE'
const CANCEL_INVOICE = 'CANCEL_INVOICE'
const REFUND_INVOICE = 'REFUND_INVOICE'
const SELECT_INVOICE = 'SELECT_INVOICE'
const CLEAR_INVOICE = 'CLEAR_INVOICE'

export const clearInvoiceSelected = () => (dispatch) => {
  dispatch({
    type: SELECT_INVOICE,
    payload: { selectedInvoice: null },
  })
}

export const selectInvoice = (invoice) => (dispatch) => {
  dispatch({
    type: SELECT_INVOICE,
    payload: { selectedInvoice: invoice },
  })
}

export const getListInvoice = (packageType) => async (dispatch) => {
  try {
    const { msgResp } = await invoiceApi.getListInvoice(packageType)
    dispatch({
      type: GET_LIST_INVOICE,
      payload: { listInvoice: msgResp },
    })
  } catch (error) {
    throw new Error(error?.msgResp || error.message)
  }
}

export const cancelOrRefundInvoice =
  ({ invoiceId, status, refund, packageType }) =>
  async (dispatch, getState) => {
    const { invoice } = getState()
    const { listInvoice } = invoice

    try {
      await invoiceApi.cancelOrRefundInvoice({ invoiceId, status, refund, packageType })
      const updatedList = listInvoice.map((item) => {
        if (item.id === invoiceId) {
          return { ...item, status }
        }
        return item
      })

      dispatch({
        type: status === CO_BEE_FEATURE_CANCEL_STATUS ? CANCEL_INVOICE : REFUND_INVOICE,
        payload: { listInvoice: updatedList },
      })
    } catch (error) {
      throw new Error(error?.msgResp || error.message)
    }
  }

const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_INVOICE:
    case SELECT_INVOICE:
    case REFUND_INVOICE:
    case CANCEL_INVOICE:
    case GET_LIST_INVOICE:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}

export default invoiceReducer
