import Header from '_components/Header'
import { DBConfig } from '_utils/indexed-db'
import React from 'react'
import { initDB } from 'react-indexed-db'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import config from '../config'
import ListRequest from '../context/ListRequest'
import './style.css'

initDB(DBConfig)

export default function Main() {
  return (
    <Router>
      <ListRequest>
        <Header />
        <Switch>
          {config.map((item, index) => {
            if (item.route === '/') {
              return <Route path={item.route} key={index} component={item.page} exact />
            }
            return <Route path={item.route} key={index} component={item.page} />
          })}
        </Switch>
      </ListRequest>
    </Router>
  )
}
