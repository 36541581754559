/* eslint-disable no-nested-ternary */
import {
  CO_BEE_FEATURE_APPROVED_STATUS,
  CO_BEE_FEATURE_CANCEL_STATUS,
  CO_BEE_FEATURE_FAILED_STATUS,
  CO_BEE_FEATURE_PENDING_STATUS,
  CO_BEE_FEATURE_REFUNDED_STATUS,
} from '_utils/constants'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'

const renderStatus = (status) => {
  switch (status) {
    case CO_BEE_FEATURE_APPROVED_STATUS:
      return <option value={CO_BEE_FEATURE_APPROVED_STATUS}>Approve</option>
    case CO_BEE_FEATURE_FAILED_STATUS:
      return <option value={CO_BEE_FEATURE_FAILED_STATUS}>Failed</option>
    case CO_BEE_FEATURE_PENDING_STATUS:
      return <option value={CO_BEE_FEATURE_PENDING_STATUS}>Pending</option>
    default:
      return null
  }
}

function InvoiceItem({ item, i, onChangeStatus, isChangingStatus, toggleModalDetail }) {
  const { listAllUsers } = useSelector((state) => state.users)

  const user = listAllUsers?.find((userItem) => userItem.id === item.createdUserId)

  return (
    <tr>
      <td className='user__detail-group-list-mumber-index'>{i + 1}</td>
      <td>
        <button
          type='button'
          onClick={() => toggleModalDetail(item)}
          className='bg-transparent border-0 p-0'
        >
          <svg
            stroke='currentColor'
            fill='currentColor'
            strokeWidth='0'
            viewBox='0 0 1024 1024'
            height='1em'
            width='1em'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 0 0 0 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z' />
          </svg>
        </button>
      </td>
      <td className='group__list-number-index'>{item.id.slice(0, 6)}</td>
      <td className='group__list__creation-date'>{user?.name}</td>
      <td className='group__list__creation-date'>{item.isRecurring ? 'Yes' : 'No'}</td>
      <td className='group__list__creation-date'>
        {moment.unix(item.createdAt).format('DD/MM/YYYY')}
      </td>
      <td className='group__list__creation-date'>
        {moment.unix(item.lastModifiedAt).format('DD/MM/YYYY')}
      </td>
      <td className='text-end' style={{ color: '#3c3c3c' }}>
        <b>${item.refund || 0}</b>
      </td>
      <td className='text-end'>
        <select
          className='form-select-sm border cursor-pointer'
          value={item.status}
          onChange={(e) => onChangeStatus(e.target.value, item)}
          disabled={isChangingStatus}
          style={
            item.status === CO_BEE_FEATURE_PENDING_STATUS
              ? { color: '#ff6903', fontSize: '1em' }
              : item.status === CO_BEE_FEATURE_APPROVED_STATUS
                ? { color: '#51aaaa', fontSize: '1em' }
                : item.status === CO_BEE_FEATURE_CANCEL_STATUS
                  ? { color: '#c0392b', fontSize: '1em' }
                  : item.status === CO_BEE_FEATURE_REFUNDED_STATUS
                    ? { color: '#2c3e50', fontSize: '1em' }
                    : item.status === CO_BEE_FEATURE_FAILED_STATUS
                      ? { color: '#34495e', fontSize: '1em' }
                      : null
          }
        >
          {isChangingStatus ? (
            <option className='text-dark'>Loading...</option>
          ) : (
            <>
              {renderStatus(item.status)}
              <option className='bg-white text-dark' value={CO_BEE_FEATURE_REFUNDED_STATUS}>
                Refunded
              </option>
              <option className='bg-white text-dark' value={CO_BEE_FEATURE_CANCEL_STATUS}>
                Cancelled
              </option>
            </>
          )}
        </select>
      </td>
    </tr>
  )
}

export default InvoiceItem
