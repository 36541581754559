import useQuery from '_hooks/useQuery'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { useIndexedDB } from 'react-indexed-db'

import noAvatar from '_images/noAvatar.jpg'

import EventItem from '../components/EventItem'
import GroupItem from '../components/GroupItem'
import InvoiceItem from '../components/InvoiceItem'
import ScheduleEventFeature from '../components/ScheduleEventFeature'

import UserPremium from '../components/UserPremium'
import './style.css'

function UserDetail() {
  const { getByIndex } = useIndexedDB('users')

  const params = useQuery()
  const idUser = params.get('uid')

  const [users, setUsers] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getByIndex('id', idUser).then((user) => {
      setUsers(user)
    })
  }, [])

  useEffect(() => {
    if (users) {
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [users])

  if (loading) {
    return <Spinner animation='border' variant='info' />
  }

  return (
    <>
      <Container>
        <Row className='user__detail-layout-top'>
          <div className='user__detail-layout-top'>
            <div className='user__detail__avatar'>
              <img src={users?.photoUrl || noAvatar} alt='' className='user__detail__avatar-img' />
            </div>
            <div className='user__detail__name__created-date'>
              <p>{users?.name}</p>
              <h3>Date created: {moment.unix(users?.createdAt).format('DD/MM/YYYY')}</h3>
            </div>
          </div>
        </Row>

        <Row xs={1} sm={1} lg={1}>
          <Col lg={3}>
            <div className='user__detail__contact'>
              <h4 className='user__detail__contact-heading'>Contact user</h4>
              <div className='user__detail__contact-info'>
                <div className='user__detail__contact-info-box'>
                  <p className='user__detail__contact-info-phone'>
                    <b>Phone: </b>
                    <span>{users?.phone}</span>
                  </p>
                  <p className='user__detail__contact-info-email'>
                    <b>Email:</b>
                    <span>{users?.email}</span>
                  </p>
                </div>
              </div>
            </div>

            <div className='user__detail__info'>
              <h4 className='user__detail__info-heading'>Other information</h4>
              <div className='user__detail__info-other'>
                <div className='user__detail__info-other-box'>
                  <p>
                    <b>Postal Code: </b> {users?.postalCode ? users?.postalCode : 'No Postal Code'}
                  </p>
                  <p>
                    <b>Last Modified:</b> {moment.unix(users?.lastModifiedAt).format('DD/MM/YYYY')}
                  </p>
                  <p>
                    <b>resetPassword RequestTime:</b> <b>{users?.resetPasswordRequestTime}</b>
                  </p>
                </div>
              </div>
            </div>

            <div className='user__detail__premium'>
              <h4 className='user__detail__premium-heading'>Feature of user</h4>
              <div className='user__detail__premium-status'>
                <ScheduleEventFeature scheduleFeatures={users?.scheduleFeatures} userInfo={users} />
                <UserPremium premiumFeature={users?.premiumFeature} userInfo={users} />
              </div>
            </div>
          </Col>

          <Col lg={9}>
            <h4 className='user__detail-group-list-heading'>Invoice</h4>
            <div className='scroll__mobile'>
              <InvoiceItem />
            </div>

            <h4 className='user__detail-group-list-heading'>Groups</h4>
            <div className='scroll__mobile'>
              <GroupItem uid={idUser} />
            </div>

            <h4 className='user__detail-event-list-heading'>Events</h4>
            <div className='scroll__mobile'>
              <EventItem uid={idUser} />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default UserDetail
