/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'

import useQuery from '_hooks/useQuery'
import { getListInvoice } from '_redux/reducers/invoice'
import {
  CO_BEE_FEATURE_APPROVED_STATUS,
  CO_BEE_FEATURE_CANCEL_STATUS,
  CO_BEE_FEATURE_FAILED_STATUS,
  CO_BEE_FEATURE_PENDING_STATUS,
  CO_BEE_FEATURE_REFUNDED_STATUS,
  PACKAGE_TYPE_PREMIUM_ACCOUNT,
} from '_utils/constants'

const renderStatus = (status) => {
  switch (status) {
    case CO_BEE_FEATURE_APPROVED_STATUS:
      return <span style={{ color: '#51aaaa' }}>Approved</span>
    case CO_BEE_FEATURE_FAILED_STATUS:
      return <span style={{ color: '#34495e' }}>Failed</span>
    case CO_BEE_FEATURE_PENDING_STATUS:
      return <span style={{ color: '#ff6903' }}>Pending</span>
    case CO_BEE_FEATURE_CANCEL_STATUS:
      return <span style={{ color: '#c0392b' }}>Cancelled</span>
    case CO_BEE_FEATURE_REFUNDED_STATUS:
      return <span style={{ color: '#2c3e50' }}>Refunded</span>
    default:
      return null
  }
}

function InvoiceItem() {
  const dispatch = useDispatch()
  const query = useQuery()

  const uid = query.get('uid')

  const [listInvoiceState, setListInvoiceState] = useState([])

  const { listInvoice } = useSelector((state) => state.invoice)

  useEffect(() => {
    dispatch(getListInvoice(PACKAGE_TYPE_PREMIUM_ACCOUNT))
  }, [])

  useEffect(() => {
    if (listInvoice?.length) {
      const filteredList = listInvoice.filter((item) => item.createdUserId === uid)
      setListInvoiceState(filteredList)
    }
  }, [JSON.stringify(listInvoice)])

  const tableHeaders = [
    '#',
    'Id',
    'Package Name',
    'Recurring',
    'Created Date',
    'Last Modified',
    'Status',
    'Refund ($)',
  ]

  return (
    <>
      <ToastContainer autoClose={2000} />
      <table className='table '>
        <thead>
          <tr className='user__detail-group-list-title'>
            {tableHeaders.map((item, i) => (
              <th key={i} className={item === 'Refund ($)' ? 'text-end' : 'text-start'}>
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {listInvoiceState.length ? (
            <>
              {listInvoiceState?.map((item, i) => (
                <tr key={i}>
                  <td className='user__detail-group-list-mumber-index'>{i + 1}</td>
                  <td className='user__detail-group-list-created-date'>{item.id.slice(0, 6)}</td>
                  <td className='user__detail-group-list-created-date'>{item.packageName}</td>
                  <td className='user__detail-group-list-created-date'>
                    {item.isRecurring ? 'Yes' : 'No'}
                  </td>
                  <td className='user__detail-group-list-created-date'>
                    {moment.unix(item.createdAt).format('DD/MM/YYYY')}
                  </td>
                  <td className='user__detail-group-list-created-date'>
                    {moment.unix(item.lastModifiedAt).format('DD/MM/YYYY')}
                  </td>
                  <td>{renderStatus(item.status)}</td>
                  <td className='text-end user__detail-group-list-created-date'>
                    <b>${item.refund}</b>
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan={tableHeaders.length} className='text-center fw-bold text-secondary'>
                No data
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  )
}

export default InvoiceItem
